<template>
	<section id="portfolio" class="list-useful">
		<b-row>
		    <b-col md="12">
		    	<h2 class="mb-2">Useful Information</h2>
		    </b-col>
		</b-row>
		<b-row class="list-item">
		    <b-col md="12">  
		    	<template v-if="list.length == 0">
	        		<h4 class="py-3 text-center white-bg" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
			    <template v-else>
			    	<template v-for="(item, index) in list">
			    		<router-link :to="'/participant/useful-information/' + item.id" :key="item.index">
					    	<b-card class="d-flex pr-xl-1 black-child">
					    		<div class="size-24 mb-1 medium">{{ item.title }}</div>
					    		<b-img :src="item.image_thumb" class="alignleft mb-1" v-if="item.image_thumb"></b-img>
					    		<div class="des size-18 des-four-row">
					    			{{ item.description }}
					    		</div>
					    	</b-card>
					    </router-link>
			    	</template>
			    </template>				
		    </b-col>
		</b-row>
	</section>	
 
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {
	  	data() {
		    return {		    	
			    list: []
		    }
		},
		computed: {
		    // mix the getters into computed with object spread operator
		    ...mapGetters({
		    	user: "auth/user"
		    })
		},
		created(){
			this.fetchUserful();			
		},
		methods: {
		  	fetchUserful(){
				this.$store
				.dispatch('auth/fetchUserful', {
					filter:{
						"list_search": [
							{ "field": "type","keyword": "0"},
							{ "field": "status","keyword": "1"},
						]
					},
					sort: {"field": "id","type": "desc"}
				})
				.then(response => {
					this.list = response.data.data
					console.log('this.list participant: ', this.list)
				})
				.catch((error) => {
					console.log(error)
				})
			},
		}
	};	

</script>

<style lang="scss">	

	@media only screen and (max-width: 1400px){
		.size-18{
			font-size: 15px !important;
		}
	}
	@media only screen and (max-width: 480px){
		.size-18{
			font-size: 14px !important; 
		}
	}
</style>

